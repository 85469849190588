import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { first, switchMap, tap } from 'rxjs/operators';
import { SharedActions } from '../../actions/shared.actions';
import { IUser } from '../../../models';

@Injectable()
export class UserResolver implements Resolve<IUser | null> {

  constructor(private apiService: ApiService,
              private ngRedux: NgRedux<any>,
              private sharedActions: SharedActions) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUser> | null {
    return this.ngRedux.select<IUser>(['user', 'user']).pipe(
      first(),
      switchMap((user) => {
        if (user) {
          return of(user);
        }

        // fetch user from api service and insert it into redux
        return this.apiService.getUser().pipe(
          tap(u => this.sharedActions.setUser(u)),
        );
      }),
    );
  }
}
