import { ICountryRule, IUser } from '../../models';
import { actions } from './../../data/store/index.reducers';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedActions {

  constructor(private ngRedux: NgRedux<any>) { }

  setOauthParams(oauthParams: {clientId?: string, locale?: string, redirectUri?: string, state?: string}) {
    this.ngRedux.dispatch({
      type: actions.SET_OAUTH_PARAMS,
      payload: oauthParams,
    });
  }

  setReturnUrl(url?: string) {
    this.ngRedux.dispatch({
      type: actions.SET_RETURN_URL,
      payload: url,
    });
  }

  setLocale(locale: string) {
    this.ngRedux.dispatch({
      type: actions.SET_LOCALE,
      payload: locale,
    });
  }

  setAppConfig(appConfig: Object) {
    this.ngRedux.dispatch({
      type: actions.SET_APP_CONFIG,
      payload: appConfig,
    });
  }

  setCountryRules(data: ICountryRule) {
    this.ngRedux.dispatch({
      type: actions.SET_COUNTRY_RULES,
      payload: data,
    });
  }

  setPermissions(permissions: Object[]) {
    this.ngRedux.dispatch({
      type: actions.SET_PERMISSIONS,
      payload: permissions,
    });
  }

  setDateOfBirth(dateOfBirth: any) {
    this.ngRedux.dispatch({
      type: actions.SET_SIGNUP_DETAILS,
      payload: { dateOfBirth },
    });
  }

  setDateOfBirthLocked(dateOfBirthLocked: boolean) {
    this.ngRedux.dispatch({
      type: actions.SET_DATE_OF_BIRTH_LOCKED,
      payload: dateOfBirthLocked,
    });
  }

  setSignUpDisabled(value: boolean) {
    this.ngRedux.dispatch({
      type: actions.SET_SIGNUP_DISABLED,
      payload: value,
    });
  }

  setLoginDisabled(value: boolean) {
    this.ngRedux.dispatch({
      type: actions.SET_LOGIN_DISABLED,
      payload: value,
    });
  }

  setUser(user: IUser) {
    this.ngRedux.dispatch({
      type: actions.SET_USER,
      payload: user,
    });
  }
}
