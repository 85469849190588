import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kws-sso-text',
  templateUrl: './kws-sso-text.component.html',
  styleUrls: ['./kws-sso-text.component.sass'],
})
export class KwsSsoTextComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
