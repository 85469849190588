import { Injectable } from '@angular/core';
import { select } from '@angular-redux/store';
import { combineLatest, Observable } from 'rxjs';
import { HelpersService } from '../helpers/helpers.service';
import * as tinycolor from 'tinycolor2';
import { AppConfigService } from '../app-config/app-config.service';

export interface KwsBranding {
  primaryColor?: string;
  logoUrl?: string;
  faviconUrl?: string;
  privacyPolicyUrl?: string;
  termsAndConditionsUrl?: string;
  appName?: string;
  originAppId?: number;
  ssoTextSignupPage?: string;
  ssoTextParentEmailPage?: string;
  ssoTextSuccessPage?: string;
}

export const DEFAULT_LANGUAGE = 'en';

export const DEFAULT_FAVICONURL = 'assets/images/default-favicon.ico';

@Injectable()
export class KwsBrandingService {
  @select(['config', 'appConfig']) appConfig$: Observable<any>;
  @select(['config', 'locale']) locale$: Observable<string>;
  branding: KwsBranding = {
    primaryColor: '#1962CA',
    logoUrl: 'assets/images/appicon.png',
    faviconUrl: DEFAULT_FAVICONURL,
  };

  private getTranslatedValue = (key, appConfig, locale, returnDefaultLang = true) => {
    let url;
    const rootLang = locale?.substring(0, 2);
    if (appConfig[key]) {
      if (appConfig[key][locale]) {
        url = appConfig[key][locale];
      } else if (appConfig[key][rootLang]) {
        url = appConfig[key][rootLang];
      } else if (returnDefaultLang && appConfig[key][DEFAULT_LANGUAGE]) {
        url = appConfig[key][DEFAULT_LANGUAGE];
      }
    }
    return url;
  }

  constructor(
    private helpers: HelpersService,
    private config: AppConfigService,
  ) {
    combineLatest(this.appConfig$, this.locale$).subscribe(([appConfig, locale]) => {
      this.branding = helpers.defaults(
        {
          privacyPolicyUrl: this.getTranslatedValue('privacyPolicyUrl', appConfig, locale),
          termsAndConditionsUrl: this.getTranslatedValue('termsAndConditionsUrl', appConfig, locale),
          primaryColor: appConfig.mainContainerBgColour,
          logoUrl: config.getCdnAssetUrl(this.getTranslatedValue('logoUrl', appConfig, locale)),
          faviconUrl: config.getCdnAssetUrl(this.getTranslatedValue('faviconUrl', appConfig, locale)),
          appName: appConfig.name,
          originAppId: appConfig.id,
          ssoTextSignupPage: this.getTranslatedValue('ssoTextSignupPage', appConfig, locale, false),
          ssoTextParentEmailPage: this.getTranslatedValue('ssoTextParentEmailPage', appConfig, locale, false),
          ssoTextSuccessPage: this.getTranslatedValue('ssoTextSuccessPage', appConfig, locale, false),
        },
        this.branding,
      );

      const bgBrightness = tinycolor(this.branding.primaryColor).getBrightness();
      const primaryDark = tinycolor(this.branding.primaryColor).darken(10).toString();
      const primaryLight = bgBrightness > 240 ? primaryDark : tinycolor(this.branding.primaryColor).setAlpha(.1);
      document.documentElement.style.setProperty('--primary-light', primaryLight);

      this.applyFaviconUrl(this.branding.faviconUrl);
    });
  }

  applyFaviconUrl(faviconUrl: string) {
    let linkElem: HTMLLinkElement = document.querySelector('link[rel="icon"]');

    if (!linkElem) {
      linkElem = document.createElement('link');
      linkElem.rel = 'icon';
      linkElem.type = 'image/png';
      document.head.appendChild(linkElem);
    }

    linkElem.href = faviconUrl;
  }

  getBranding(): KwsBranding {
    return this.branding;
  }

  getLogoUrl(): string {
    return this.branding.logoUrl;
  }

  getPrimaryColor(): string {
    return this.branding.primaryColor;
  }

  getPrivacyPolicyUrl(): string {
    return this.branding.privacyPolicyUrl;
  }

  getTermsAndConditionsUrl(): string {
    return this.branding.termsAndConditionsUrl;
  }

  getAppName(): string {
    return this.branding.appName;
  }

  getTextSignupPage(): string {
    return this.branding.ssoTextSignupPage;
  }

  getTextParentEmailPage(): string {
    return this.branding.ssoTextParentEmailPage;
  }

  getTextSuccessPage(): string {
    return this.branding.ssoTextSuccessPage;
  }

  getColorBrightness(color: string): number {
    return tinycolor(color).getBrightness();
  }

  getDarkenedColor(color: string, percentage: number): string {
    return tinycolor(color).darken(percentage).toString();
  }

  getColorWithAlpha(color: string, alpha: number): string {
    return tinycolor(color).setAlpha(alpha).toString();
  }
}

export class MockKwsBrandingService {

  constructor() { }

  getBranding() {
    return { originAppId: null };
  }

  getPrivacyPolicyUrl() {
    return 'https://www.kidswebservices.com/privacy-policy';
  }

  getTermsAndConditionsUrl() {
    return 'https://www.kidswebservices.com/terms-of-use';
  }

  getPrimaryColor() {
    return 'red';
  }

  getLogoUrl() {
    return 'https://superawesome.tv/logo.png';
  }

  getColorBrightness(color): number {
    return 100;
  }

  getTextSignupPage(): string {
    return 'singupPageText';
  }

  getTextParentEmailPage(): string {
    return 'parentsEmailPageText';
  }

  getTextSuccessPage(): string {
    return 'successPageText';
  }

  getDarkenedColor(color: string, percentage: number): string {
    return color;
  }
}
