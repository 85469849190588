import { Component, OnInit, Input } from '@angular/core';
import { IPermission } from '../../../models';

@Component({
  selector: 'app-kws-permissions-description',
  templateUrl: './kws-permissions-description.component.html',
  styleUrls: ['./kws-permissions-description.component.sass'],
})
export class KwsPermissionsDescriptionComponent implements OnInit {
  @Input() permissions: IPermission[];
  constructor() { }

  ngOnInit() {
  }

}
