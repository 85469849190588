<button [disabled]="(formGroup && !formGroup.valid) || loading || disabled"
        [ngClass]="{'loading': loading, 'disabled': disabled || (formGroup && !formGroup.valid), 'inverse': preset === 'inverse'}"
        [ngStyle]="disabled ? {} : style"
        [class]="'btn btn-primary btn-block btn-lg ' + class"
        [type]="btnType"
        (click)="click"
>
  <span class="text">{{text}}</span>
  <fa-icon *ngIf="loading" [icon]="faSpinner" [spin]="true"></fa-icon>
</button>
