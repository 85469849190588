import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api/api.service';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UsernameValidatorService {

  delay = 500;

  constructor(private apiService: ApiService) {
  }

  /**
   * Return an async validator function for the username field. Checks the username using the `checkUsername` method of the API service.
   */
  public validate(control: AbstractControl): Observable<ValidationErrors | null> {

    return timer(this.delay)
      .pipe(
        // fire off a call to the api service to check the username
        switchMap(clientId => this.apiService.checkUsername(control.value)),
        // return null if username is available, otherwise return {usernameTaken:true}
        map(result => result.available ? null : {
          usernameTaken: true,
        }),
        // if there was an error, pretend the username was taken
        catchError(err => of({ usernameTaken: true })),
      );
  }
}
