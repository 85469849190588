import { EventsService } from './../events/events.service';
import { Injectable } from '@angular/core';
import * as abTesting from 'ab-testing';

class ABTest {
  testObject: any;
  userCondition: any;

  constructor(abTestName: string, weights?: number[], userCondition?: any) {
    this.testObject = abTesting.createTest(abTestName, [
      { name: 'A', weight: weights ? weights[0] : undefined },
      { name: 'B', weight: weights ? weights[1] : undefined },
    ]);
    this.userCondition = userCondition;
  }

  getGroup(userId: any, userData: any = {}) {
    if (this.userCondition) {
      let meetsConditions = true;
      Object.keys(this.userCondition).forEach((key) => {
        if (!meetsConditions) {
          return;
        }
        if (typeof this.userCondition[key] === 'function') {
          meetsConditions = this.userCondition[key](userData[key]);
        } else {
          meetsConditions = (userData[key] === this.userCondition[key]);
        }
      });
      if (!meetsConditions) {
        return 'A';
      }
    }
    return this.testObject.getGroup(`${userId}`);
  }
}

@Injectable()
export class AbTestingService {

  constructor(private eventsService: EventsService) {
  }

  private getGroup(testName: string, identifier: any): 'A' | 'B' {
    const abTest = new ABTest(testName);
    const testGroup = abTest.getGroup(identifier);
    return testGroup;
  }

  public getGiveDeviceToGrownupGroup(locale: string): 'A' | 'B' {
    if (locale !== 'en') {
      return 'A';
    }
    const sessionId = this.eventsService.getSessionId();
    return this.getGroup('give-device-grownup', sessionId);
  }

  /**
   * Return true if the user should be in the 'is parent nearby' cohort. Approx 10% of users should be.
   * (KWS-7851)
   */
  public getIsParentNearbyTest(): boolean {
    const abTest = new ABTest('is-parent-nearby', [0.9, 0.1]);
    const sessionId = this.eventsService.getSessionId();
    return abTest.getGroup(sessionId) === 'B';
  }
}
