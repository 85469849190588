export const getTalonFlow = (): string => {
  const hostname = window.location.hostname;
  if (
    hostname.includes('staging') ||
    hostname.includes('localhost') ||
    hostname.includes('gamedev')
  ) {
    return 'kws_registration_dev';
  }
  return 'kws_registration_prod';
};
