import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { KwsBrandingService } from '../../../shared/services/kws-branding/kws-branding.service';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kws-sso-parent-email-page',
  templateUrl: './kws-sso-parent-email-page.component.html',
  styleUrls: ['./kws-sso-parent-email-page.component.sass'],
})

export class KwsSsoParentEmailPageComponent implements OnInit, OnChanges {
  @select(['registration', 'showParentCopy']) showParentCopy$: Observable<boolean>;

  @Input() appName: string;
  @Input() loading = false;
  @Input() permissions = [];
  @Input() title: string;
  @Input() description: string;
  @Input() customPageDescription: string;
  @Input() buttonHeaderText: string;
  @Input() buttonText: string;
  @Input() parentEmail: string;
  @Input() parentEmailLocked: boolean;
  @Input() responseError: any;
  @Output() parentEmailChange: EventEmitter<void> = new EventEmitter();
  @Output() parentEmailSubmit: EventEmitter<void> = new EventEmitter();
  form: FormGroup;
  faEnvelope = faEnvelope;
  privacyPolicyUrl: string;

  constructor(private kwsBrandingService: KwsBrandingService) { }

  ngOnInit() {
    this.form = new FormGroup(
      { parentEmail: new FormControl('', [Validators.required, Validators.email]) },
    );

    this.form.patchValue({ parentEmail: this.parentEmail }, { emitEvent: false });

    this.form.valueChanges.subscribe((data) => {
      this.parentEmailChange.emit(data.parentEmail);
    });

    this.privacyPolicyUrl = this.kwsBrandingService.getPrivacyPolicyUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form && changes.parentEmail && changes.parentEmail.currentValue !== changes.parentEmail.previousValue) {
      this.form.patchValue({ parentEmail: changes.parentEmail.currentValue }, { emitEvent: false });
    }
  }

  onSubmit() {
    this.parentEmailSubmit.emit();
  }
}
