import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer } from './data/store/index.reducers';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppConfigService } from './shared/services/app-config/app-config.service';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegistrationActions } from './registration/actions/registration.actions';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';
import { OauthProxyComponent } from './oauth-proxy/oauth-proxy.component';
import { MarkdownModule } from 'ngx-markdown';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const split = url.split('?');
    const queryString = split[1] ? `?${split[1]}` : '';
    return super.parse(split[0].toLowerCase() + queryString);
  }
}

@NgModule({
  imports: [
    BrowserModule,
    NgReduxModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MarkdownModule.forRoot(),
  ],
  declarations: [AppComponent, OauthProxyComponent],
  providers: [
    AppConfigService,
    AuthGuardService,
    RegistrationActions,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public ngRedux: NgRedux<any>, private devTools: DevToolsExtension) {
    let enhancers = [];
    let middleWares = [];
    if (this.devTools.isEnabled()) {
      enhancers = [...enhancers, devTools.enhancer()];
      middleWares = [...middleWares, reduxImmutableStateInvariant()];
    }
    ngRedux.configureStore(rootReducer, {}, middleWares, enhancers);
  }
}
