<kws-input
  [formGroup]="formGroup"
  [large]="large"
  [faIconLeft]="icons.faUser"
  [placeholder]="'Display name' | translate"
  [controlPath]="controlPath"
  disabled="true"
  inputType="text"
>
</kws-input>

<kws-collapse [hintText]="'button-whats-this-for' | translate"
              [hintRight]="true">
  {{'privacy-notice-sign-up-displayname' | translate:{app_name: (appConfig$ | async)?.name} }}
</kws-collapse>

<app-kws-error *ngIf="error">
  <span translate>Sorry, username generation isn't working right now</span>
  <fa-icon [icon]="icons.faFrown" [fixedWidth]="true"></fa-icon>
</app-kws-error>

<button class="btn btn-block btn-link btn-generate"
        type="button"
        (click)="getRandomUsername()"
        [disabled]="disabled || loading || error">
  <span translate>Generate</span>
  <fa-icon [icon]="icons.faSync" [spin]="loading" [fixedWidth]="true"></fa-icon>
</button>
