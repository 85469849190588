import { IPermission, IOAuthParams } from '../../models';
import { SharedActions } from '../../shared/actions/shared.actions';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../../shared/services/api/api.service';
import { AppConfigService } from '../../shared/services/app-config/app-config.service';
import { switchMap, tap, map } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppConfigResolver implements Resolve<void> {
  constructor(
    private apiService: ApiService,
    private reduxActions: SharedActions,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private appConfig: AppConfigService,
  ) {}

  private checkRedirectUriValid(inputParams: any, oauthCallbackUrls: string[]) {
    if (inputParams.skipRedirect !== true && !inputParams.redirectUri) {
      if (!inputParams.return) {
        console.error('Expected either skipRedirect or redirectUri to be set.');
      }
      return false;
    }

    if (inputParams.skipRedirect && inputParams.redirectUri) {
      console.error('skipRedirect and redirectUri cannot be set at the same time. Please remove one of them.');
      return false;
    }

    if (inputParams.redirectUri && oauthCallbackUrls.indexOf(inputParams.redirectUri) === -1) {
      console.error('the redirectUri does not match any of the redirect URIs set in the control panel.');
      return false;
    }

    return (!!inputParams.return || inputParams.skipRedirect === true || oauthCallbackUrls.indexOf(inputParams.redirectUri) > -1);
  }

  private checkCodeChallengeValid(oauthParams: any) {
    if (oauthParams.codeChallenge && !oauthParams.codeChallengeMethod) {
      console.error('if codeChallenge is set, codeChallengeMethod needs to be set as well.');
      return false;
    }

    if (!oauthParams.codeChallenge && oauthParams.codeChallengeMethod) {
      console.error('if codeChallengeMethod is set, codeChallenge needs to be set as well.');
      return false;
    }

    return true;
  }

  private checkDateOfBirthValid(dob: string) {
    if (!dob) {
      return true;
    }
    const unixEpochPattern = /^[0-9]{13}$/; // under 13s must have 13 digits
    const valid = unixEpochPattern.test(dob);
    if (!valid) {
      console.error('dob query param is invalid');
    }
    return valid;
  }

  private checkInputParamsValid(inputParams: any, translatedPermissions: IPermission[], oauthCallbackUrls: string[]): void {
    const permsValid = translatedPermissions.length === inputParams.permissionsToRequest.length;
    const redirectUriValid = this.checkRedirectUriValid(inputParams, oauthCallbackUrls);
    const dateOfBirthValid = this.checkDateOfBirthValid(inputParams.dob);
    const codeChallengeValid = this.checkCodeChallengeValid(inputParams);

    if (!permsValid) {
      console.error('At least one of the provided permissions to request does not exist for this app');
    }

    const registrationEnabled = permsValid && redirectUriValid && dateOfBirthValid && codeChallengeValid;
    const loginEnabled = Boolean(inputParams.return) || (permsValid && redirectUriValid && codeChallengeValid);

    this.reduxActions.setSignUpDisabled(!registrationEnabled);
    this.reduxActions.setLoginDisabled(!loginEnabled);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    const transformDate = (date: number) => {
      const dateString = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC');
      const parts = dateString.split('-');
      return {
        year: Number(parts[0]),
        month: Number(parts[1]),
        day: Number(parts[2]),
      };
    };

    // hide signup if the hideSignUp param is true OR there's no redirectUri or skipRedirect=true
    const hideSignUp = route.queryParams.hideSignUp === 'true' ||
      !(route.queryParams.redirectUri || route.queryParams.skipRedirect === 'true');

    const oauthParams: IOAuthParams = {
      hideSignUp,
      clientId: route.queryParams.clientId || this.appConfig.getSSOClientId(),
      redirectUri: route.queryParams.redirectUri,
      state: route.queryParams.state,
      permissionsToRequest: route.queryParams.permissionsToRequest ? route.queryParams.permissionsToRequest.split(',') : [],
      automaticActivation: route.queryParams.automaticActivation === 'true',
      skipRedirect: route.queryParams.skipRedirect === 'true',
      hideSignIn: route.queryParams.hideSignIn === 'true',
      codeChallenge: route.queryParams.codeChallenge,
      codeChallengeMethod: route.queryParams.codeChallengeMethod,
    };

    // Removing undefined values from ouathParams
    Object.keys(oauthParams).forEach(key => oauthParams[key] === undefined && delete oauthParams[key]);

    this.translate.use(route.params.locale);
    this.reduxActions.setOauthParams(oauthParams);
    this.reduxActions.setLocale(route.params.locale);
    this.reduxActions.setReturnUrl(route.queryParams.return);

    return combineLatest(this.apiService.getAppConfig(oauthParams.clientId), this.apiService.getAgeForCountry())
      .pipe(
        // save app config to store
        tap(([appConfig, countryRules]) => {
          this.reduxActions.setAppConfig({...appConfig.app, identityName: appConfig.identityName});
          this.reduxActions.setCountryRules(countryRules);

          const dateOfBirth = (route.queryParams.dob && this.checkDateOfBirthValid(route.queryParams.dob)) ?
            transformDate(Number(route.queryParams.dob)) : null;
          this.reduxActions.setDateOfBirth(dateOfBirth);
          this.reduxActions.setDateOfBirthLocked(!!dateOfBirth);
        }),
        // fetch permissions for this app ID
        switchMap(([appConfig, countryRules]) => {
          if (oauthParams.permissionsToRequest.length === 0) {
            return of([appConfig, []]);
          }
          return this.apiService.getTranslatedPermissions(appConfig.app.id, oauthParams.permissionsToRequest)
          .pipe(map(res => [appConfig, res]));
        }),
        // save permissions to store
        tap(([appConfig, permissions]: [any, IPermission[]]) => {
          this.reduxActions.setPermissions(permissions);
          this.checkInputParamsValid(
            { ...oauthParams, dob: route.queryParams.dob, return: route.queryParams.return },
            permissions,
            appConfig.app.oauthCallbackUrls,
          );
        }),
      );
  }
}
