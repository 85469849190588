import { switchMap, tap, catchError, first, delay, finalize } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { select } from '@angular-redux/store';
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Observable, EMPTY, noop, iif, of } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { KwsBrandingService } from '../../services/kws-branding/kws-branding.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IOAuthParams, IActivationData } from '../../../models';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.sass'],
  animations: [
    trigger('isVisible', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('true => false', animate('0ms')),
      transition('false => true', animate('500ms')),
    ]),
  ],
})
export class ActivationComponent implements OnInit {

  @select(['config', 'oauthParams']) oauthParams$: Observable<IOAuthParams>;
  @select(['config', 'locale']) locale$: Observable<string>;
  @select(['config', 'appConfig']) appConfig$: Observable<any>;
  @Output() activationSuccess: EventEmitter<void> = new EventEmitter();
  @Output() permissionsRequired: EventEmitter<void> = new EventEmitter();
  @Input() forceActivation: boolean;
  @Input() permissionsDisplayed: boolean;
  icons = { faUser, faSpinner };
  formLoading = false;
  displayNameRequired = false;
  form: FormGroup;
  appId;
  privacyPolicyUrl: string;
  termsAndConditionsUrl: string;

  constructor(
    private apiService: ApiService,
    private kwsBrandingService: KwsBrandingService,
  ) {
  }

  ngOnInit() {
    this.appId = this.kwsBrandingService.getBranding().originAppId;
    this.privacyPolicyUrl = this.kwsBrandingService.getPrivacyPolicyUrl();
    this.termsAndConditionsUrl = this.kwsBrandingService.getTermsAndConditionsUrl();

    this.form = new FormGroup(
      {
        displayName: new FormControl(
          '',
          [
            Validators.required,
            Validators.minLength(3),
          ],
        ),
      },
    );

    return this.oauthParams$.pipe(
      first(),
      // Delay (of 0) required so any event emitted doesn't happen in the same cycle and cause a ExpressionChangedAfterItHasBeenCheckedError
      delay(0),
      switchMap((oauthParams) => {
        if (this.forceActivation) {
          return this.handleUserNotActivated(oauthParams, this.permissionsDisplayed);
        }

        return this.authorise(oauthParams).pipe(
          tap(resp => this.activationComplete(resp)),
          catchError((err) => {
            if (err.status === 403) {
              return this.handleUserNotActivated(oauthParams, this.permissionsDisplayed);
            }
            throw err;
          }),
        );
      }),
    )
      .subscribe();
  }

  private activationComplete(resp) {
    // Activation complete, whether it was necessary or not.
    this.activationSuccess.emit(resp);
  }

  private authorise(oauthParams): Observable<any> {
    return this.appConfig$
      .pipe(
        first(),
        switchMap((appConfig) => {
          return this.apiService.authorise(
            {
              clientId: oauthParams.clientId,
              redirectUri: oauthParams.redirectUri,
              state: oauthParams.state,
              codeChallenge: oauthParams.codeChallenge,
              codeChallengeMethod: oauthParams.codeChallengeMethod,
            },
            appConfig && appConfig.oauthImplicitEnabled,
          );
        }),
      );
  }

  private handleUserNotActivated(oauthParams: IOAuthParams, permissionsDisplayed: boolean): Observable<any> {
    if (!permissionsDisplayed && oauthParams.permissionsToRequest && oauthParams.permissionsToRequest.length > 0) {
      this.permissionsRequired.emit();
      return EMPTY;
    }
    if (oauthParams.automaticActivation) {
      return this.activate(oauthParams, null);
    }
    this.displayNameRequired = true;
    return EMPTY;
  }

  private activate(oauthParams, username?: string): Observable<any> {
    const activationData: IActivationData = { username, appName: oauthParams.clientId };

    if (oauthParams.permissionsToRequest) {
      activationData.permissions = oauthParams.permissionsToRequest;
    }

    return this.apiService.activateApp(activationData)
      .pipe(
        switchMap(() =>
          iif(
            // if forceActivation is true, skip the `authorize` step
            () => oauthParams.skipRedirect,
            of({}),
            this.authorise(oauthParams),
          ),
        ),
        tap(resp => this.activationComplete(resp)),
      );
  }

  onSubmit() {
    this.formLoading = true;
    const username = this.form.value.displayName;

    this.oauthParams$.pipe(
      first(),
      switchMap(oauthParams => this.activate(oauthParams, username)),
      finalize(() => this.formLoading = false),
    )
      .subscribe();
  }
}
