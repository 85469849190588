<app-kws-sso-header
  [title]="title"
  [customDescription]="customPageDescription"
  [description]="description">
</app-kws-sso-header>
<form [formGroup]="form"
      (submit)="onSubmit()">

  <app-kws-permissions-description *ngIf="(showParentCopy$ | async) === undefined"
                                   [permissions]="permissions"></app-kws-permissions-description>

  <kws-input
    [formGroup]="form"
    [large]="true"
    [faIconLeft]="faEnvelope"
    placeholder="{{'Parent\'s email address' | translate}}"
    controlPath="parentEmail"
    inputType="email"
    [disabled]="parentEmailLocked"
  >
  </kws-input>

  <app-kws-error
    *ngIf="form.controls.parentEmail.touched && form.get('parentEmail').errors && form.get('parentEmail').errors.required"
  >{{'Oops! You need to provide a parent email' | translate }}</app-kws-error>
  <app-kws-error
    *ngIf="form.controls.parentEmail.touched && form.get('parentEmail').errors && form.get('parentEmail').errors.email"
  >{{'Oops! It looks like the parent email is not valid' | translate }}</app-kws-error>

  <!-- if there's a responseError, save it in the `err` template variable -->
  <ng-container *ngIf="responseError">
    <app-kws-error
      *ngIf="responseError?.parentEmailInvalid"
    >{{'Oops! It looks like the parent email is not valid' | translate }}</app-kws-error>
    <app-kws-error
      *ngIf="responseError?.serverError || responseError?.unknownError"
    >{{'Oops! Something went wrong, please try again' | translate }}</app-kws-error>
  </ng-container>

  <app-kws-text class="buttonHeaderText">{{buttonHeaderText}}</app-kws-text>

  <app-kws-sso-link *ngIf="privacyPolicyUrl"
                    [url]="privacyPolicyUrl">{{'Privacy Policy' | translate}}</app-kws-sso-link>
  <app-kws-sso-link *ngIf="!privacyPolicyUrl" url="#">[Please add Privacy Policy link via Control Panel]
  </app-kws-sso-link>

  <app-kws-branded-button [formGroup]="form"
                          [loading]="loading"
                          class="btn-next-parent-email"
                          text="{{buttonText}}">
  </app-kws-branded-button>
</form>
