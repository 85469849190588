import { Component, OnInit, Input } from '@angular/core';
import { KwsBrandingService } from '../../services/kws-branding/kws-branding.service';

@Component({
  selector: 'app-kws-sso-logo',
  templateUrl: './kws-sso-logo.component.html',
  styleUrls: ['./kws-sso-logo.component.sass'],
})

export class KwsSsoLogoComponent implements OnInit {
  @Input() logoUrl: string;

  constructor(private kwsBrandingService: KwsBrandingService) {}

  ngOnInit() {
    this.logoUrl = this.kwsBrandingService.getLogoUrl();
  }
}
