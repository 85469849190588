<div class="container">
  <div class="holder">
    <div class="row">
      <div class="col-md-12 col-centered">
        <app-kws-sso-title>{{title}}</app-kws-sso-title>
      </div>
    </div>
    <div class="row" *ngIf="!hideLogo">
      <div class="col-md-12 col-centered">
        <app-kws-sso-logo url="assets/images/appicon.png"></app-kws-sso-logo>
      </div>
    </div>
    <div class="row">
      <div *ngIf="customDescription" class="col-md-12 col-no-padding">
        <app-kws-text class="description">{{customDescription}}</app-kws-text>
      </div>
      <div *ngIf="!customDescription" class="col-md-12 col-no-padding">
        <app-kws-text *ngIf="description" class="description">{{description}}</app-kws-text>
        <app-kws-text *ngFor="let description of descriptionLines" class="description">{{description}}</app-kws-text>
      </div>
    </div>
  </div>
</div>
