<kws-input
  [formGroup]="formGroup"
  [large]="large"
  [faIconLeft]="faIconLeft"
  [placeholder]="placeholder"
  [controlPath]="controlPath"
  [disabled]="disabled"
  inputType="text"
  (focus)="onFocus()"
  >
</kws-input>
