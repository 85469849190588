import { Component, OnInit } from '@angular/core';
import { KwsBrandingService } from '../../services/kws-branding/kws-branding.service';

@Component({
  selector: 'app-kws-alert',
  templateUrl: './kws-alert.component.html',
  styleUrls: ['./kws-alert.component.sass'],
})
export class KwsAlertComponent implements OnInit {

  backgroundColor: string;

  constructor(private kwsBrandingService: KwsBrandingService) {
  }

  ngOnInit() {
    this.backgroundColor = this.kwsBrandingService.getColorWithAlpha(
      this.kwsBrandingService.getPrimaryColor(),
      0.1,
    );
  }

}
