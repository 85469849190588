import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {

  constructor() {}

  once(func, scope?) {
    let n = 1;
    let result;
    return function () {
      if (n > 0) {
        n -= 1;
        result = func.apply(scope || this, arguments);
      } else {
        result = undefined;
      }
      return result;
    };
  }

  unique(array) {
    return array.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }

  defaults(obj, defaults) {
    const keys = this.unique([...Object.keys(obj), ...Object.keys(defaults)]);
    keys.map((key) => {
      if (!obj[key]) {
        obj[key] = defaults[key];
      }
    });
    return obj;
  }

  getRedirectUri(data: {token?: string, code?: string, state?: string, redirectUri?: string}) {
    let query;

    if (data.token) {
      query = `${data.state ? `?state=${data.state}` : ''}#token=${data.token}`;
    } else {
      query = `?code=${data.code}${data.state ? `&state=${data.state}` : ''}`;
    }

    return `${data.redirectUri}${query}`;
  }
}
