import { NgModule, Injectable } from '@angular/core';
import {
  RouterModule,
  Routes,
  Resolve,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AppConfigResolver } from './services/app-config-resolver/app-config-resolver';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { Observable } from 'rxjs';
import { OauthProxyComponent } from './oauth-proxy/oauth-proxy.component';
@Injectable()
export class ResetPasswordResolver implements Resolve<void> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
    const locale = route.queryParams.language;
    const user = route.queryParams.user;
    const token = route.queryParams.password_reset_token;
    const app = route.queryParams.app;
    this.router.navigate([locale, 'password', 'reset', user, token], {
      queryParams: { clientId: app },
    });
    return;
  }
}

const routes: Routes = [
  {
    path: 'auth',
    resolve: {
      params: ResetPasswordResolver,
    },
    children: [],
  },
  {
    path: 'oauth',
    component: OauthProxyComponent,
  },
  {
    path: ':locale',
    children: [
      {
        path: 'register',
        loadChildren: './registration/registration.module#RegistrationModule',
      },
      {
        path: 'login',
        loadChildren: './login/login.module#LoginModule',
      },
      {
        path: 'password',
        loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule',
        resolve: {},
      },
      {
        path: 'forgot-pass',
        redirectTo: 'password/forgot',
      },
      {
        path: 'reset-pass/:username/:token',
        redirectTo: 'password/reset/:username/:token',
      },
      {
        path: 'login-confirm',
        loadChildren: './login-confirm/login-confirm.module#LoginConfirmModule',
      },
      {
        path: 'user',
        loadChildren: './user/user.module#UserModule',
        canActivate: [AuthGuardService],
      },
    ],
    resolve: {
      appConfig: AppConfigResolver,
    },
  },
  {
    path: '**',
    redirectTo: '/en/register',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
  providers: [AppConfigResolver, ResetPasswordResolver],
})
export class AppRoutingModule {
  constructor() {}
}
