import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kws-sso-link',
  templateUrl: './kws-sso-link.component.html',
  styleUrls: ['./kws-sso-link.component.sass'],
})
export class KwsSsoLinkComponent implements OnInit {
  @Input() url: string;
  @Input() routerUrl: string;
  constructor() { }

  ngOnInit() {
  }
}
