import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigService } from './services/app-config/app-config.service';
import { ApiService } from './services/api/api.service';
import { TokenService } from './services/token/token.service';
import { KwsSsoLogoComponent } from './components/kws-sso-logo/kws-sso-logo.component';
import { KwsTextComponent } from './components/kws-text/kws-text.component';
import { KwsInputTextComponent } from './components/kws-input-text/kws-input-text.component';
import { KwsBrandingService } from './services/kws-branding/kws-branding.service';
import { AbTestingService } from './services/ab-testing/ab-testing.service';
import { KwsBrandedButtonComponent } from './components/kws-branded-button/kws-branded-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KwsSsoLinkComponent } from './components/kws-sso-link/kws-sso-link.component';
import { KwsSsoTextComponent } from './components/kws-sso-text/kws-sso-text.component';
import { KwsSsoHeaderComponent } from './components/kws-sso-header/kws-sso-header.component';
import { KwsSsoTitleComponent } from './components/kws-sso-title/kws-sso-title.component';
import { KwsPermissionsDescriptionComponent } from './components/kws-permissions-description/kws-permissions-description.component';
import { KwsSsoFooterComponent } from './components/kws-sso-footer/kws-sso-footer.component';
import { KwsErrorComponent } from './components/kws-error/kws-error.component';
import { KwsSsoParentEmailPageComponent } from './components/kws-sso-parent-email-page/kws-sso-parent-email-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedActions } from './actions/shared.actions';
import { TranslateModule } from '@ngx-translate/core';
import { KwsComponentsModule } from '@superawesome/kws-angular-components';
import { ActivationComponent } from './components/activation/activation.component';
import { KwsInputDisplaynameComponent } from './components/kws-input-displayname/kws-input-displayname.component';
import { UsernameValidatorService } from '../registration/services/username-validator.service';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { KwsAlertComponent } from './components/kws-alert/kws-alert.component';
import { UserResolver } from './services/user-resolver/user-resolver';
import { KwsPasswordComponent } from './components/kws-password/kws-password.component';
import { KwsCollapseComponent } from './components/kws-collapse/kws-collapse.component';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

// array of components this module declares AND exports
const declaredExportedComponents = [
  KwsTextComponent,
  KwsInputTextComponent,
  KwsSsoLogoComponent,
  KwsBrandedButtonComponent,
  KwsSsoLinkComponent,
  KwsSsoTextComponent,
  KwsSsoHeaderComponent,
  KwsSsoTitleComponent,
  KwsPermissionsDescriptionComponent,
  KwsSsoFooterComponent,
  KwsErrorComponent,
  ActivationComponent,
  KwsInputDisplaynameComponent,
  KwsSsoParentEmailPageComponent,
  KwsAlertComponent,
  KwsPasswordComponent,
  KwsCollapseComponent,
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    KwsComponentsModule,
    TranslateModule,
    RouterModule,
    MarkdownModule.forChild(),
  ],
  providers: [KwsBrandingService, AbTestingService, DatePipe, UsernameValidatorService],
  declarations: [...declaredExportedComponents, KwsAlertComponent],
  exports: [...declaredExportedComponents, TranslateModule, KwsComponentsModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AppConfigService,
        ApiService,
        SharedActions,
        TokenService,
        AbTestingService,
        UserResolver,
        UsernameValidatorService,
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFn,
          multi: true,
          deps: [AppConfigService],
        },
      ],
    };
  }
}
