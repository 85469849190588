import { AfterViewInit, Component, ContentChild, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'kws-password',
  templateUrl: './kws-password.component.html',
  styleUrls: ['./kws-password.component.css'],
})
export class KwsPasswordComponent implements AfterViewInit {

  @ContentChild('input') passwordInput: ElementRef<HTMLInputElement>;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    if (!this.passwordInput) {
      throw new Error('Element kws-password expected to find child input element tagged with #input!');
    }

    this.renderer.addClass(this.passwordInput.nativeElement, 'kws-password-input');
  }

  toggleShowPassword() {
    const type = this.passwordInput.nativeElement.getAttribute('type');
    this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', type === 'password' ? 'text' : 'password');
  }
}
