import { AnyAction, combineReducers } from 'redux';

export const actions = {
  SET_OAUTH_PARAMS: 'SET_OAUTH_PARAMS',
  SET_RETURN_URL: 'SET_RETURN_URL',
  SET_APP_CONFIG: 'SET_APP_CONFIG',
  SET_COUNTRY_RULES: 'SET_COUNTRY_RULES',
  SET_LOCALE: 'SET_LOCALE',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_PARENT_EMAIL: 'SET_PARENT_EMAIL',
  SET_SIGNUP_DETAILS: 'SET_SIGNUP_DETAILS',
  SET_STEP: 'SET_STEP',
  SET_DATE_OF_BIRTH_LOCKED: 'SET_DATE_OF_BIRTH_LOCKED',
  SET_SIGNUP_DISABLED: 'SET_SIGNUP_DISABLED',
  SET_RESPONSE_ERROR: 'SET_RESPONSE_ERROR',
  SET_FORGOT_PASSWORD_USERNAME: 'SET_FORGOT_PASSWORD_USERNAME',
  SET_FORGOT_PASSWORD_LOADING: 'SET_FORGOT_PASSWORD_LOADING',
  SET_RESET_PASSWORD_DATA: 'SET_RESET_PASSWORD_DATA',
  SET_LOGIN_DETAILS: 'SET_LOGIN_DETAILS',
  SET_LOGIN_STEP: 'SET_LOGIN_STEP',
  SET_LOGIN_DISABLED: 'SET_LOGIN_DISABLED',
  SET_LOGIN_RESPONSE_ERROR: 'SET_LOGIN_RESPONSE_ERROR',
  SET_LOGIN_PARENT_EMAIL: 'SET_LOGIN_PARENT_EMAIL',
  SET_PERMISSIONS_DISPLAYED: 'SET_PERMISSIONS_DISPLAYED',
  SET_DELETE_ACCOUNT_LOADING: 'SET_DELETE_ACCOUNT_LOADING',
  SET_USER: 'SET_USER',
  SET_APPS: 'SET_APPS',
};

const INITIAL_REGISTRATION_STATE = {
  step: 'sign-up',
};

const INITIAL_LOGIN_STATE = {
  step: 'login',
  permissionsDisplayed: false,
};

const configReducer = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_OAUTH_PARAMS:
      return { ...state, oauthParams: action.payload };
    case actions.SET_RETURN_URL:
      return { ...state, returnUrl: action.payload };
    case actions.SET_APP_CONFIG:
      return { ...state, appConfig: action.payload };
    case actions.SET_COUNTRY_RULES:
      return { ...state, countryRules: action.payload };
    case actions.SET_LOCALE:
      return { ...state, locale: action.payload };
    case actions.SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};

const registrationReducer = (state: any = INITIAL_REGISTRATION_STATE, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_SIGNUP_DETAILS: {
      const { username, password, dateOfBirth } = action.payload;

      return {
        ...state,
        username,
        password,
        dateOfBirth,
        responseError: null,
      };
    }
    case actions.SET_PARENT_EMAIL:
      return {
        ...state,
        parentEmail: action.payload,
      };
    case actions.SET_STEP:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_SIGNUP_DISABLED:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.SET_DATE_OF_BIRTH_LOCKED:
      return {
        ...state,
        dateOfBirthLocked: action.payload,
      };
    case actions.SET_RESPONSE_ERROR:
      return {
        ...state,
        responseError: action.payload,
      };
    default:
      return state;
  }
};

const loginReducer = (state: any = INITIAL_LOGIN_STATE, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_LOGIN_DETAILS: {
      const { username, password } = action.payload;

      return {
        ...state,
        username,
        password,
        responseError: null,
      };
    }
    case actions.SET_LOGIN_PARENT_EMAIL: {
      return {
        ...state,
        parentEmail: action.payload,
      };
    }
    case actions.SET_LOGIN_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case actions.SET_LOGIN_DISABLED:
      return {
        ...state,
        disabled: action.payload,
      };
    case actions.SET_LOGIN_RESPONSE_ERROR:
      return {
        ...state,
        responseError: action.payload,
      };
    case actions.SET_PERMISSIONS_DISPLAYED:
      return {
        ...state,
        permissionsDisplayed: action.payload,
      };
    default:
      return state;
  }
};

const passwordRecoveryReducer = (state: any = { forgotPassword: { loading: false }, resetPassword: {} }, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_FORGOT_PASSWORD_USERNAME: {
      const forgotPassword = { ...state.forgotPassword, username: action.payload };
      return { ...state, forgotPassword };
    }
    case actions.SET_FORGOT_PASSWORD_LOADING: {
      const forgotPassword = { ...state.forgotPassword, loading: action.payload };
      return { ...state, forgotPassword };
    }
    case actions.SET_RESET_PASSWORD_DATA: {
      const resetPassword = { ...state.resetPassword, ...action.payload };
      return { ...state, resetPassword };
    }
    default:
      return state;
  }
};

const userReducer = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_USER: {
      return { ...state, user: action.payload };
    }
    case actions.SET_APPS: {
      return { ...state, apps: action.payload };
    }
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  config: configReducer,
  registration: registrationReducer,
  login: loginReducer,
  user: userReducer,
  passwordRecovery: passwordRecoveryReducer,
});
