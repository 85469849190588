import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-kws-input-text',
  templateUrl: './kws-input-text.component.html',
})
export class KwsInputTextComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() controlPath: string | string[];
  @Input() large = false;
  @Input() faIconLeft: IconDefinition;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Output() focus: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onFocus() {
    this.focus.emit();
  }
}
