import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';
import { DatePipe } from '@angular/common';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { EventsService } from '../events/events.service';
import { TokenService } from '../token/token.service';
import {
  IUser,
  IRandomNames,
  IAuthoriseData,
  ICountryRule,
  IPermission,
  IActivationData,
  INewUser,
} from '../../../models';
import { UsernameService } from '../username.service';

@Injectable()
export class ApiService {
  @select(['config', 'locale']) locale$: Observable<string>;

  headers: object;
  checkedUsernames: object = {};
  checkedUsernamesSubjects: object = {};

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private datepipe: DatePipe,
    private events: EventsService,
    private token: TokenService,
    private usernameService: UsernameService,
  ) {
    this.locale$.subscribe((locale) => {
      this.headers = { 'Accept-Language': locale };
    });
  }

  private errorHandler(err): any {
    if (err instanceof HttpErrorResponse) {
      if (err.error instanceof ErrorEvent) {
        console.error('API service client-side error: ' + err.error.message);
      } else {
        console.error(`API service server-side error: ${err.status} ${err.message}`);
      }
    }

    throw err;
  }

  private get(url: string, opts: any = { headers: {} }): Observable<any> {
    return this.http
      .get(this.appConfig.getApiUrl() + url, {
        ...opts,
        headers: { ...opts.headers, ...this.headers },
      })
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  private post(url: string, data: any, opts: any = { headers: {} }): Observable<any> {
    return this.http
      .post(this.appConfig.getApiUrl() + url, data, {
        ...opts,
        headers: { ...opts.headers, ...this.headers },
      })
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  checkUsername(username: string): Observable<{ available: boolean }> {
    if (this.checkedUsernames[username]) {
      return of(this.checkedUsernames[username]);
    }
    if (!this.checkedUsernamesSubjects[username]) {
      // NOTE: This is converting the cold observable into a hot one in order to avoid several calls
      // to be made in case this function is called several times in a row (issue that happened in
      // Pokemon Go with Opera Mini browser in Android but that we were not able to reproduce)
      // The implementation is based on this article: https://medium.com/@benlesh/hot-vs-cold-observables-f8094ed53339
      this.checkedUsernamesSubjects[username] = new Subject();
      const params = { username };
      const observable = this.get(`/v1/users/check-username`, { params }).pipe(
        switchMap((response) => {
          this.checkedUsernames[username] = response;
          return of(this.checkedUsernames[username]);
        }),
      );
      observable.subscribe(this.checkedUsernamesSubjects[username]);
    }

    return new Observable((observer) =>
      this.checkedUsernamesSubjects[username].subscribe(observer),
    );
  }

  getAppConfig(oauthClientId: string): Observable<any> {
    const params = { oauthClientId };
    return this.get(`/v2/apps/config`, { params });
  }

  getAgeForCountry(): Observable<ICountryRule> {
    return this.get(`/v1/countries/child-age`);
  }

  getTranslatedPermissions(
    appId: number,
    permissions: string[],
  ): Observable<IPermission[]> {
    const params = {
      permissions: permissions.join(','),
    };
    return this.get(`/v2/apps/${appId}/permissions/translated`, { params });
  }

  createUser(newUserData: INewUser): Observable<any> {
    const dateOfBirth = newUserData.dateOfBirth
      ? this.datepipe.transform(newUserData.dateOfBirth, 'yyyy-MM-dd')
      : newUserData.dateOfBirth;
    const data = { ...newUserData, dateOfBirth };
    const opts = {
      headers: {
        'x-kws-session-id': this.events.getSessionId(),
      },
    };

    return this.post('/v2/users', data, opts);
  }

  activateApp(appActivationData: IActivationData): Observable<any> {
    const tokenData = this.token.getPayload();
    const headers = {
      Authorization: 'Bearer ' + this.token.get(),
    };
    return this.post(`/v1/users/${tokenData.userId}/apps`, appActivationData, {
      headers,
    });
  }

  /**
   * Gets a token with 'sso' scope and superawesomeclub as the clientId, allowing us to activate the user if required.
   * This is not the token that should be sent back after the redirect.
   * @param username username of the user signing in
   * @param password password of the user signing in
   */
  login(username: string, password: string): Observable<any> {
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);
    const headers = {
      Authorization: `Basic ${btoa('superawesomeclub:superawesomeclub')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-kws-session-id': this.events.getSessionId(),
    };

    return this.post(`/oauth/token`, body.toString(), { headers }).pipe(
      tap((resp) => {
        this.token.set(resp.access_token);
        this.usernameService.username = username;
      }),
    );
  }

  authorise(data: IAuthoriseData, implicitFlow = false) {
    const responseType = implicitFlow ? 'token' : 'code';

    const body = new URLSearchParams();
    body.set('response_type', responseType);
    body.set('client_id', data.clientId);
    body.set('redirect_uri', data.redirectUri);
    if (data.state) {
      body.set('state', data.state);
    }
    if (data.codeChallenge) {
      body.set('code_challenge', data.codeChallenge);
    }
    if (data.codeChallengeMethod) {
      body.set('code_challenge_method', data.codeChallengeMethod);
    }

    const headers = {
      Authorization: `Bearer ${this.token.get()}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.post(`/oauth/authorise`, body.toString(), { headers })
      .pipe
      // tap(resp => this.token.set(resp.access_token)),
      ();
  }

  forgotPassword(username: string, appName?: string) {
    return this.post('/v1/users/forgot-password', { username, appName });
  }

  resetPassword(newPassword: string, token: string, appName?: string) {
    return this.post('/v1/users/reset-password', { newPassword, token, appName });
  }

  randomNames(appId): Observable<IRandomNames> {
    return this.get(`/v1/apps/${appId}/random-names`);
  }

  getRandomDisplayName(appId: number): Observable<string> {
    return this.get(`/v2/apps/${appId}/random-display-name`);
  }

  getUser(): Observable<IUser> {
    const tokenData = this.token.getPayload();
    const headers = {
      Authorization: 'Bearer ' + this.token.get(),
    };

    return this.get(`/v1/users/${tokenData.userId}`, { headers });
  }

  getApps(): Observable<any> {
    const tokenData = this.token.getPayload();
    const headers = {
      Authorization: 'Bearer ' + this.token.get(),
    };

    return this.get(`/v1/users/${tokenData.userId}/apps`, {
      headers,
      params: {
        excludePrimary: true,
      },
    });
  }

  deleteAccount(password: String): Observable<any> {
    const tokenData = this.token.getPayload();
    const headers = {
      Authorization: 'Bearer ' + this.token.get(),
    };

    return this.post(
      `/v1/users/${tokenData.userId}/delete-account`,
      { password },
      { headers },
    );
  }
}
