import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

const LOCAL_STORAGE_STRING = 'AUTH_TOKEN';

@Injectable()
export class TokenService {
  // fallback in-memory storage for the token if sessionStorage is not available
  private token: string;

  constructor() {}

  private isInvalid() {
    const token = this.getToken();
    let decoded;
    try {
      decoded = jwtDecode(token);
    } catch (e) {
      return true;
    }
    const now = new Date();
    const expires = new Date(decoded.exp * 1000);
    return now > expires;
  }

  get(): any {
    const token = this.getToken();
    if (token && this.isInvalid()) {
      this.delete();
      return null;
    }
    return token;
  }

  getPayload(): any {
    const token = this.get();
    return token && jwtDecode(token);
  }

  public isValid(): boolean {
    return !this.isInvalid();
  }

  private getToken(): string {
    return this.token;
  }

  set(token: string): void {
    this.token = token;
  }

  delete(): void {
    delete this.token;
  }
}
