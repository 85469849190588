import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../../shared/services/token/token.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenService.isValid()) {
      return true;
    }
    this.router.navigate([`/${route.params.locale}/login`], {
      queryParams: { return: state.url },
    });
    return false;
  }
}
