import { Component, HostBinding, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { KwsBrandingService } from '../../services/kws-branding/kws-branding.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-kws-branded-button',
  templateUrl: './kws-branded-button.component.html',
  styleUrls: ['./kws-branded-button.component.sass'],
})
export class KwsBrandedButtonComponent implements OnInit {
  @HostBinding('attr.style')
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--branded-button-hover-color: ${this.hoverColor}`);
  }
  primaryColor: string;
  hoverColor: string;
  @Input() text: string;
  @Input() loading: boolean;
  @Input() disabled = false;
  @Input() formGroup: FormGroup;
  @Input() btnType = 'submit';
  @Input() preset: string;
  @Input() class = '';
  @Output() click: EventEmitter<void> = new EventEmitter();
  faSpinner = faSpinner;
  textColor = '#FFF';
  border: string;
  style: Object;

  constructor(private sanitizer: DomSanitizer, private kwsBrandingService: KwsBrandingService) {
  }

  ngOnInit() {
    this.primaryColor = this.kwsBrandingService.getPrimaryColor();

    const bgBrightness = this.kwsBrandingService.getColorBrightness(this.primaryColor);
    if (bgBrightness >= 200) {
      this.textColor = this.kwsBrandingService.getDarkenedColor(this.primaryColor, 50);
    }
    if (bgBrightness >= 250) {
      this.border = this.kwsBrandingService.getDarkenedColor(this.primaryColor, 20);
    }

    if (this.preset === 'inverse') {
      this.hoverColor = this.kwsBrandingService.getDarkenedColor(this.textColor, 18);
      this.style = { 'background-color': this.textColor, color: this.primaryColor, 'border-color': this.primaryColor };
    } else {
      this.hoverColor = this.kwsBrandingService.getDarkenedColor(this.primaryColor, 18);
      this.style = { 'background-color': this.primaryColor, color: this.textColor, 'border-color': this.border };
    }
  }
}
