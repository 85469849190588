import { Component, OnInit, Input } from '@angular/core';
import { faSync, faUser, faFrown } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { select } from '@angular-redux/store';

@Component({
  selector: 'app-kws-input-displayname',
  templateUrl: './kws-input-displayname.component.html',
  styleUrls: ['./kws-input-displayname.component.scss'],
})
export class KwsInputDisplaynameComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() large: boolean;
  @Input() controlPath;
  @Input() disabled: boolean;
  @Input() appId;
  @Input() locale: string;
  icons = { faSync, faUser, faFrown };
  loading = false;
  randomDisplayName$: Observable<string>;
  error: Error;
  @select(['config', 'appConfig']) appConfig$: Observable<any>;

  constructor(
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.randomDisplayName$ = this.apiService.getRandomDisplayName(this.appId).pipe(
      catchError((err) => {
        this.loading = false;
        throw err;
      }),
    );

    this.getRandomUsername();
  }

  async getRandomUsername(): Promise<void> {
    this.loading = true;
    this.randomDisplayName$.subscribe((randomDisplayName) => {
      this.formGroup.get(this.controlPath).setValue(randomDisplayName);
      this.loading = false;
    });
  }
}
