<div class="main-container-bg" [style.background-color]="bgColourRgba">
  <div class="container">
    <div [@isVisible]="routeLoading" class="loading-screen">
      <div class="loading-content">
        <fa-icon [icon]="faSpinner" [spin]="true" size="2x"></fa-icon>
      </div>
    </div>

    <div [@isVisible]="!routeLoading" class="main-container-inner mx-auto col-12 col-md-10 col-lg-8 col-xl-6">
      <router-outlet></router-outlet>
    </div>

    <div *ngIf="routeError" [@isVisible]="!routeLoading && routeError"
         class="loading-screen">
      <div class="loading-content">
        <app-kws-sso-header title="{{'app-error-title' | translate}}"
                            description="{{'app-error-description' | translate}}"
                            hideLogo="true"></app-kws-sso-header>

        <app-kws-branded-button
          *ngIf="retryUrl"
          [routerLink]="retryUrl"
          btnType="button"
          [text]="'button-retry' | translate"></app-kws-branded-button>
      </div>


    </div>
  </div>
</div>
