import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kws-sso-header',
  templateUrl: './kws-sso-header.component.html',
  styleUrls: ['./kws-sso-header.component.sass'],
})
export class KwsSsoHeaderComponent implements OnInit {
  @Input() customDescription: string;
  @Input() description: string;
  @Input() descriptionLines: string[];
  @Input() title: string;
  @Input() hideLogo = false;
  constructor() { }

  ngOnInit() {

  }

}
