import { actions } from '../../data/store/index.reducers';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { RegistrationStep } from '../../data/types';

@Injectable()
export class RegistrationActions {

  constructor(private ngRedux: NgRedux<any>) {
  }

  setParentEmail(parentEmail: string) {
    this.ngRedux.dispatch({
      type: actions.SET_PARENT_EMAIL,
      payload: parentEmail,
    });
  }

  setSignupDetails(payload) {
    this.ngRedux.dispatch({
      payload,
      type: actions.SET_SIGNUP_DETAILS,
    });
  }

  setStep(step: RegistrationStep, showParentCopy?: boolean) {
    this.ngRedux.dispatch({
      type: actions.SET_STEP,
      payload: { step, showParentCopy },
    });
  }

  setResponseError(payload) {
    this.ngRedux.dispatch({
      payload,
      type: actions.SET_RESPONSE_ERROR,
    });
  }
}
