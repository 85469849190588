import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { select } from '@angular-redux/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import {
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { AppConfigService } from './shared/services/app-config/app-config.service';
import { getTalonFlow } from './shared/utils';

const MAIN_BG_ALPHA = 0.1;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  animations: [
    trigger('isVisible', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('true => false', animate('0ms')),
      transition('false => true', animate('500ms')),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'sa-kws-kids-sso';
  @select(['config', 'appConfig', 'mainContainerBgColour'])
  mainContainerBgColour$: Observable<string>;
  bgColourRgba: string;
  routeLoading = true;
  routeError = null;
  retryUrl = null;

  // fa icons
  faSpinner = faSpinner;
  showMandrillOutageBanner = false;

  constructor(translate: TranslateService, router: Router) {
    router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((event) => {
        this.routeError = null;
        if (event instanceof NavigationStart) {
          this.routeLoading = true;
        }
        if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
          this.routeLoading = false;
        }
        if (event instanceof NavigationError) {
          this.routeLoading = false;
          this.routeError = event.error;
          this.retryUrl = event.url;
        }
      });

    translate.setDefaultLang('en');
  }

  async ngOnInit() {
    this.mainContainerBgColour$.subscribe((rgbColor) => {
      if (!rgbColor) {
        return;
      }
      const red = parseInt(rgbColor.substring(1, 3), 16);
      const green = parseInt(rgbColor.substring(3, 5), 16);
      const blue = parseInt(rgbColor.substring(5, 7), 16);
      this.bgColourRgba = `rgba(${red}, ${green}, ${blue}, ${MAIN_BG_ALPHA})`;
    });
    await this.loadTalon();
  }

  async loadTalon(): Promise<void> {
    const flow = getTalonFlow();
    await window['talon'].loadSync({ flow });
  }
}
