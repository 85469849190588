<div [@isVisible]="!displayNameRequired" class="activating-container">
  <fa-icon [icon]="icons.faSpinner" [spin]="true" size="2x" class="activating-icon"></fa-icon>
</div>

<ng-container *ngIf="displayNameRequired">
  <app-kws-sso-header
    title="{{'Join' | translate}}"
    description="{{'Click Generate to find your perfect display name! This will be seen by other users.' | translate}}">
  </app-kws-sso-header>
  <form [formGroup]="form"
        (submit)="onSubmit()">

    <app-kws-input-displayname
      [formGroup]="form"
      [large]="true"
      [disabled]="disabled"
      [appId]="appId"
      [locale]="locale$ | async"
      controlPath="displayName"
    ></app-kws-input-displayname>

    <app-kws-error
      *ngIf="form.get('displayName').touched && form.get('displayName').errors?.required"
    >{{'Oops! You need to provide a display name' | translate }}
    </app-kws-error>

    <app-kws-error
      *ngIf="form.get('displayName').touched && form.get('displayName').errors?.minlength"
    >{{'Oops! Display name must be at least 3 letters long' | translate }}</app-kws-error>

    <app-kws-error
      *ngIf="form.get('displayName').touched && form.get('displayName').errors?.pattern"
    >{{'Oops! This display name is not allowed, please try something else' | translate }}
    </app-kws-error>

    <app-kws-sso-text translate>
      By clicking JOIN you agree to our <a [href]="privacyPolicyUrl" translate>Privacy Policy</a> &amp;
      <a [href]="termsAndConditionsUrl" translate>Terms and Conditions</a>
    </app-kws-sso-text>

    <app-kws-branded-button [formGroup]="form"
                            [loading]="formLoading"
                            text="{{'Join' | translate}}">
    </app-kws-branded-button>
  </form>
</ng-container>
