import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DEFAULT_LANGUAGE } from '../shared/services/kws-branding/kws-branding.service';

@Component({
  selector: 'app-oauth-proxy',
  template: '',
})
export class OauthProxyComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    const { language, ...queryParams } = this.route.snapshot.queryParams;

    this.router.navigate([language || DEFAULT_LANGUAGE, 'register'], { queryParams });
  }
}
