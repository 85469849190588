import { animate, style, transition, trigger } from '@angular/animations';

const fadeHeightStart = style({
  opacity: 0,
  height: 0,
  'margin-bottom': 0,
  'padding-top': 0,
  'padding-bottom': 0,
});
const fadeHeightEnd = style({
  opacity: 1,
  height: '*',
  'margin-bottom': '*',
  'padding-top': '*',
  'padding-bottom': '*',
});

/**
 * Similar to the above, but also animates margins/paddings/heights, so that any elements in the same layout container
 * will appear to move smoothly.
 */
export const fadeHeight = trigger('fadeHeight', [
  transition(':enter', [style({ 'overflow-y': 'hidden' }), fadeHeightStart, animate('.2s ease-in', fadeHeightEnd)]),
  transition(':leave', [style({ 'overflow-y': 'hidden' }), fadeHeightEnd, animate('.2s ease-out', fadeHeightStart)]),
]);
