import { Component } from '@angular/core';

@Component({
  selector: 'app-kws-error',
  templateUrl: './kws-error.component.html',
  styleUrls: ['./kws-error.component.sass'],
})
export class KwsErrorComponent {
  constructor() { }
}
