import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kws-text',
  templateUrl: './kws-text.component.html',
  styleUrls: ['./kws-text.component.sass'],
})
export class KwsTextComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }

}
