import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';
import { v1 as uuid } from 'uuid';
import { catchError } from 'rxjs/operators';

export interface EventUserData {
  language: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private url: string;
  private authString: string;
  private sessionId: string;

  constructor(private http: HttpClient, appConfig: AppConfigService) {
    const eventsSetup = appConfig.getEventsSetup();
    this.url = eventsSetup.url + '/v2/events';
    this.authString = eventsSetup.authString;
    this.sessionId = uuid();
  }

  // This function should be invoked when a user logs out to generate a new id to be sent in the events
  renewSessionId() {
    this.sessionId = uuid();
  }

  getSessionId(): string {
    return this.sessionId;
  }

  sendEvent(eventName: string, eventData: any = {}, userData: EventUserData, sessionData: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this.authString }),
    };
    const body = {
      session: {
        ...sessionData,
        user: { ...userData },
        id: this.sessionId,
      },
      events: [{
        name: eventName,
        data: eventData,
      }],
    };

    return this.http.post(this.url, body, httpOptions)
    .pipe(
      // silently catch & log error without re-throwing
      catchError((err) => {
        console.error(err);
        return EMPTY;
      }),
    );
  }

  signupStart(eventData, userData: EventUserData) {
    return this.sendEvent('signup_start', eventData, userData).subscribe();
  }

  signupUsername(eventData, userData: EventUserData) {
    return this.sendEvent('signup_username', eventData, userData).subscribe();
  }

  signupParentEmail(eventData, userData: EventUserData) {
    return this.sendEvent('signup_parent_email', eventData, userData).subscribe();
  }

  signupDisplayName(eventData, userData: EventUserData) {
    return this.sendEvent('signup_display_name', eventData, userData).subscribe();
  }

  signupGenarateDisplayName(eventData, userData: EventUserData) {
    return this.sendEvent('signup_generate_display_name', eventData, userData).subscribe();
  }

  signupError(eventData, userData: EventUserData) {
    return this.sendEvent('signup_error', eventData, userData).subscribe();
  }

  loginStart(eventData, userData: EventUserData) {
    return this.sendEvent('login_start', eventData, userData).subscribe();
  }

  loginError(eventData, userData: EventUserData) {
    return this.sendEvent('login_error', eventData, userData).subscribe();
  }

  signupParentIsCloseAbTest(showParentCopy: boolean, userData: EventUserData) {
    return this.sendEvent('signup_pre_parent_email', { showParentCopy, v: 2 }, userData).subscribe();
  }
  generatedUsernameFailedValidation(userData: EventUserData) {
    return this.sendEvent('generated_username_failed_validation', {}, userData).subscribe();
  }
  generatedUsernameFailedValidationBlockedRegistration(userData: EventUserData) {
    return this.sendEvent('generated_username_failed_validation_blocked_registration', {}, userData).subscribe();
  }
}
