import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface EventsSetup {
  url: string;
  authString: string;
}

@Injectable()
export class AppConfigService {
  private apiUrl: string;
  private eventsUrl: string;
  private eventsAuthString: string;
  private authDelayMs = 0;
  private ssoClientId = 'superawesomeclub';
  private assetsCdn: { [replace: string]: string} = {};

  constructor(private http: HttpClient) { }

  loadAppConfig(): Promise<any> {
    return this.http.get('/config.json')
      .toPromise()
      .then((data: any) => {
        this.apiUrl = data.apiUrl;
        this.eventsUrl = data.eventsUrl;
        this.eventsAuthString = data.eventsAuthString;
        this.authDelayMs = data.authDelayMs || 0;
        this.assetsCdn = data.assetsCdn;
        return data;
      });
  }

  getApiUrl(): string {
    return this.apiUrl;
  }

  getEventsSetup(): EventsSetup {
    return {
      url: this.eventsUrl,
      authString: this.eventsAuthString,
    };
  }

  getSSOClientId(): string {
    return this.ssoClientId;
  }

  getAuthDelayMs(): number {
    return this.authDelayMs;
  }

  /**
   * Helper function to CDN-ify an asset URL.
   * @param url
   */
  getCdnAssetUrl(url: string): string {
    if (!url) {
      // avoid issue where null favicon or logo is passed in
      return url;
    }

    // loop through the keys in our assetsCdn map
    return Object.keys(this.assetsCdn)
      .reduce(
        (resultingUrl, replaceKey) => {
          // replace the key in our input url with the value in the assetsCdn map
          return resultingUrl.replace(replaceKey, this.assetsCdn[replaceKey]);
        },
        url);
  }
}
