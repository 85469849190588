import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { fadeHeight } from '../../../anims';

let elementId = 0;

@Component({
  selector: 'kws-collapse',
  templateUrl: './kws-collapse.component.html',
  styleUrls: ['./kws-collapse.component.scss'],
  animations: [fadeHeight]
})
export class KwsCollapseComponent implements OnInit {

  // what text to use for the clickable toggle (default: "what's this for?")
  @Input() hintText?: string;

  /**
   * Whether or not to display the hint text on the right hand side
   */
  @Input() hintRight = false;

  @ViewChild('dropdownContent') dropdownContent;

  // tslint:disable-next-line:no-increment-decrement
  id = elementId++;
  showDropdown = false;
  dropdownId = `kws-collapse-${this.id}`;
  buttonId = `kws-collapse-${this.id}`;
  animatingOpen = false;

  ngOnInit() {
  }

  onClick() {
    this.showDropdown = !this.showDropdown;
  }

  /**
   * Hook into the animation start so that we can make sure we scroll the dropdown element into view if it would
   * go below the bottom of the screen.
   *
   * Seems like there should be a better, more 'angular' way to do this ¯\_(ツ)_/¯
   */
  animationStart(event) {
    this.animatingOpen = this.showDropdown;

    if (this.animatingOpen) {
      const animationCallback = () => {
        // check if the bottom of our expanding dropdown is below the viewport
        const elementBottomOutsideViewport = this.dropdownContent.nativeElement.getBoundingClientRect().bottom
          > (window.innerHeight || document.documentElement.clientHeight);

        // if it is, scroll the bottom of the element into view
        if (elementBottomOutsideViewport) {
          this.dropdownContent.nativeElement.scrollIntoView({ block: 'end', behavior: 'auto' });
        }

        // do another animation frame callback if the animation is still ongoing.
        if (this.animatingOpen) {
          requestAnimationFrame(animationCallback);
        }
      };

      requestAnimationFrame(animationCallback);
    }
  }

  /**
   * Hook into animation end so we can set `animatingOpen` to false and kill off our `requestAnimationFrame` chain.
   */
  animationEnd(event) {
    this.animatingOpen = false;
  }
}
