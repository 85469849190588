<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-centered">
      <a *ngIf="url" target="_blank" [href]="url">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
      <a *ngIf="routerUrl" [routerLink]="routerUrl">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </div>
  </div>
</div>
