<button class="collapse-toggle"
        type="button"
        (click)="onClick()"
        [id]="buttonId"
        [class.shown]="showDropdown"
        [class.hint-position-right]="hintRight"
        [attr.aria-expanded]="showDropdown"
        [attr.aria-controls]="dropdownId">
  {{hintText}}
  <svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.70711 1.70711C8.09763 1.31658 8.09763 0.683418 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976312 6.29289 0.292893L4 2.58579L1.70711 0.292893C1.31658 -0.0976313 0.683418 -0.0976314 0.292893 0.292893C-0.0976315 0.683417 -0.0976315 1.31658 0.292893 1.70711L3.29289 4.70711C3.68342 5.09763 4.31658 5.09763 4.70711 4.70711C4.70724 4.70697 4.70738 4.70683 4.70752 4.70669L7.70711 1.70711Z"
          fill="black"/>
  </svg>
</button>

<div *ngIf="showDropdown"
     class="collapse-dropdown content-padding background"
     [id]="dropdownId"
     [attr.aria-labelledby]="buttonId"
     @fadeHeight
     (@fadeHeight.start)="animationStart($event)"
     (@fadeHeight.done)="animationEnd($event)"
     #dropdownContent>
  <ng-content></ng-content>
</div>

