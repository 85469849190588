import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UsernameService {
  _username: string;

  constructor() {}

  get username() {
    return (
      this._username ||
      (window.sessionStorage && window.sessionStorage.getItem('username'))
    );
  }

  set username(username: string) {
    this._username = username;
    if (window.sessionStorage) {
      window.sessionStorage.setItem('username', username);
    }
  }

  clear() {
    delete this._username;
    if (window.sessionStorage) {
      window.sessionStorage.removeItem('username');
    }
  }
}
